import axios from 'axios';

const $api = axios.create({
  withCredentials: true,
  baseURL: process.env.REACT_APP_API_URL,
});

export const send = async (requestParams) => {
  try {
    const response = await $api.post(
      '/api/zk',
      requestParams
    );

    return !!response;
  } catch (e) {
    console.log(e.response?.data?.message);
  }
};
