import styles from './RequestPopup.module.css';
import { useEffect, useState } from 'react';
import CloseIcon from '../../icons/CloseIcon/CloseIcon';
import { FormField } from './FormField/FormField';
import { send } from '../../../api/api';
import NotifyPopup from '../NotifyPopup/NotifyPopup';
import Spinner from '../Spinner/Spinner';

const RequestPopup = ({
  type,
  title,
  subtitle,
  img,
  alt,
  action,
  fields,
  onClose
}) => {
  const [isFetching, setIsFetching] = useState(false);
  const [data, setData] = useState({
    type,
    fields
  });
  const [popupParams, setPopupParams] = useState({
    isShow: false,
    isSuccess: false,
    title: '',
    message: ''
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      popupParams.isShow && onClose();
    }, 10000);

    return () => clearTimeout(timer);
  }, [onClose, popupParams.isShow]);

  const onChangeHandler = ({ fieldName, value }) => {
    const fields = data.fields.map(item => {
      if (item.fieldName === fieldName) {

        return { ...item, value}
      }

      return item;
    })

    setData({ ...data, fields});
  };

  const handleActionButtonClick = () => {
    try {
      const sendData = data.fields.reduce((acc, item) => {
        acc[item.fieldName] = item.value;

        return acc;
      }, {})
      sendData.type = type;

      setIsFetching(true);

      const notValid = Object.values(sendData).some(value => value === '');

      if (notValid) {
        setPopupParams({
          isShow: true,
          isSuccess: false,
          title: 'Внимание!',
          message: 'Все поля должны быть заполнены'
        });

        setIsFetching(false);
        return;
      }

      send(sendData).then((result) => {
        setIsFetching(false);

        if (result) {
          setPopupParams({
            isShow: true,
            isSuccess: true,
            title: 'Запрос отправлен!',
            message: 'Благодарим вас за интерес к нашей компании. В ближайшее время мы свяжемся с вами для уточнения деталей'
          });

          return;
        }

        setPopupParams({
          isShow: true,
          isSuccess: false,
          title: 'Ошибка!',
          message: 'Попробуйте отправить запрос позднее или позвоните нам по телефону\n +7 (342) 2-787-687'
        })
      });
    } catch (e) {
      setIsFetching(false);
      setPopupParams({
        isShow: true,
        isSuccess: false,
        title: 'Ошибка при отправке запроса',
        message: e.message || ''
      })

      onClose();
    }
  };

  return(
    <div className={styles.container}>
      {popupParams.isShow && (
        <NotifyPopup
          title={popupParams.title}
          message={popupParams.message}
          isFetching={isFetching}
          onClose={() => {
            setPopupParams({
              ...popupParams,
              isShow: false
            });
            onClose();
          }}
        />
      )}
      <img src={img} alt={alt} className={styles.img}/>
      <div className={styles.content}>
        <div className={styles.close} onClick={onClose}>
          <CloseIcon />
        </div>
        <div className={styles.titleBlock}>
          <div className={styles.title}>{title}</div>
          <div className={styles.subtitle}>{subtitle}</div>
        </div>
        <div className={styles.inputBlock}>
          {fields.map(item => {
            return (
              <FormField
                key={item.fieldName}
                params={{
                  ...item,
                  className: styles.input,
                  onChange: onChangeHandler
              }}
              />
            )
          })}
        </div>
        <div className={styles.buttonBlock}>
          <div className={styles.button} onClick={handleActionButtonClick}>
            {isFetching ? <Spinner /> : action}
          </div>
          <div className={styles.agreement}>{'Отправляя форму вы соглашаетесь\nна обработку персональных данных.'}</div>
        </div>
      </div>
    </div>
  );
}

export default RequestPopup;
